@import '../../../themes/variables';

#sessaoNossaHistoria {
    color: $primary-color;
    border-left: 1px solid $second-theme-color;
    border-right: 1px solid $second-theme-color;
    padding: 80px 0 0 0;
    .loading{
        aspect-ratio: 2.7;
    }
    @media (max-width:991.98px){
        .desktop {
            display: none;
        }

        .mobile {
            .title {
                // display: flex;
                margin-bottom: 1rem;

                .tag {
                    width: 6px;
                    background-color: $second-theme-color;
                    align-self: stretch;
                    height: 32px;
                    margin-bottom: 0.5rem;
                }

                font-size: $fonte-exgrande;
                font-family: 'Poppins-Bold';
            }
            .carousel-indicators {
                bottom: -50px;

                & [data-bs-target] {
                    background-color: $gray-color;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    margin: 0 10px;
                }

                .active {
                    background-color: $second-theme-color;
                }
            }
            .carouselMobileItem {
                .img {
                    height: 35vh;
                    width: 100%;
                    padding: 0 !important;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .year {
                    color: $primary-color;
                    font-size: $fonte-gigante;
                    font-weight: bold;
                    margin-top: 1rem;
                }
                .text {
                    margin-top: 1rem;
                    color: $primary-color;
                    font-size: $fonte-exmedia;
                }
            }
        }
    }

    @media (min-width:991.99px) {
        .mobile {
            display: none;
        }

        .desktop {
            position: relative;
            .tag {
                height: 30px;
                width: 5px;
                background-color: $second-theme-color;
                margin-bottom: 10px;
            }
            .tituloSection {
                position: absolute;
                font-size: $fonte-grande;
                font-family: 'Poppins-Bold';
                color: $primary-color;
            }
            .selectorsLinha {
                height: 20px;
                position: absolute;
                z-index: 10;
                top: 70px;
                height: 30px;
                width: 100%;
                .linha {
                    padding-right: 7%;
                    position: relative;
                    top: 50%;
                    width: 100%;
                    transform: translate(0, -50%);
                    div{
                        background-color: $gray-color;
                        height: 3px;
                        width: 100%;
                    }
                }
                .selectors {
                    width: 85%;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .selector {
                        height: calc(30px * 0.4);
                        width: calc(30px * 0.4);
                        background-color: $gray-color;
                        border-radius: 50%;
                        cursor: pointer;

                        &.active {
                            background-color: $second-theme-color;
                            height: calc(30px * 0.6);
                            width: calc(30px * 0.6);
                        }
                    }
                }
            }
            .carouselDesktopItem {
                position: relative;
                justify-content: right;
                .leftBox {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    height: 50%;
                    min-height: 225px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        filter: brightness(60%);
                    }
                }
                .rightBox {
                    background-color: yellow;
                    padding: 150px 30px 20px 10%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    min-height: 450px;
                    background-color: transparent;
                    color: $theme-color;

                    & > * {
                        width: calc(100% - calc(var(--bs-gutter-x) * 0.5));
                    }

                    .year {
                        font-size: $fonte-exgigante;
                        font-family: 'Poppins-Bold';
                        margin-bottom: 30px;
                    }
                    .text {
                        font-family: 'Poppins-Regular';
                        font-weight: 100;
                        font-size: $fonte-media;
                    }
                    .buttons {
                        padding-top: 40px;
                        display: flex;
                        justify-content: flex-end;
                        button {
                            background-color: $second-theme-color;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: none;
                            padding: 4px 6px;
                            margin-left: 10px;
                            .buttonIcon {
                                background-color: transparent;
                                fill: $theme-color;
                                height: 30px;
                                width: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.rightBoxWallCol {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    padding-top: 40px;
    div {
        height: 100%;
        width: 100%;
        background-color: $third-theme-color;
    }
}