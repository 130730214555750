
@import '../../themes/variables';

section#seletores {
  display: flex;
  padding: 15px 50px 0;
  justify-content: flex-end;

  .ibtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    color: $theme-color;
    background-color: $third-theme-color;
    margin: 0 0.2rem;
    cursor: pointer;

    // &:active {
    //   background-color: $second-color;
    // }

    &.selected {
      background-color: $second-theme-color;
    }
  }

  .separator {
    background-color: transparent;
    width: 30px;
  }
}

@media (max-width:991.98px) {
  section#seletores {
    padding-bottom: 80px;
    padding: 40 0 !important;
    justify-content: center;

    .ibtn {
      width: 2.7rem !important;
      height: 2.7rem !important;
    }

    .separator {
      width: 2rem !important;
    }
  }
}