@import '../../../themes/variables';

#sessaoMissao {
  background-color: $second-theme-color;
  width: 100%;
  
  margin-top: 80px;
  padding: 80px 0;


  // apg Tirar esta margem após implementar sessao nossa-história
  // margin-bottom: 80px;

  .linha {
    justify-content: center;

    .coluna {
      .accordion {
        .accordion-item {
          color: white;
          border: none;
          background-color: transparent;
          .accordion-header {
            font-family: 'Poppins-Bold';
            border-bottom: 1px solid $second-color;
            button {
              color: white;
              background-color: transparent;
              border-radius: 0;
              display: flex;
              justify-content: space-between;
              box-shadow: none;
              padding-left: 0px;
              padding-right: 0px;
              text-transform: uppercase;

              &.collapsed {
                .up {
                  display: none;
                }
              }

              &:not(.collapsed){
                .down {
                  display: none;
                }
              }

              &::after {
                display: none;
              }
            
            }
          }
          .accordion-collapse {
            .accordion-body {
              font-family: 'Poppins-Regular';
              padding-left: 0;
            }
          }
        }
      }

    }
  }

  .SkeletonAccordionsContainer {
    width: 100%;
    .SkeletonAccordionItem {
      margin-top: 10px;
      width: 100%;
      height: 50px;
    }
  }
}


/*
.accordion {
  .accordion-item {
    border: none;x
    background-color: transparent;
    .accordion-header {
      button {
        background-color: transparent;
        border-radius: 0;
        &.collapsed {}
        &:not(.collapsed){}
      }
    }
    .accordion-collapse {
      .accordion-body {

      }
    }
  }
}
*/