@import '../../../themes/variables';

#sessaoCopyright {
  background-color: $primary-color;
  color: white !important;
  font-size: $fonte-expequena;

  .linha {
    width: 100%;
    height: 1px;
    background-color: $theme-color;
    margin-bottom: 10px;
  }

  .direitos {
    font-family: 'Poppins-Regular';
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .tytotech {
    display: flex;
    justify-content: flex-end;

    span {
      margin-left: 5px;
    }
  }
}
@media (max-width: 991.98px) {
  #sessaoCopyright {
    .direitos {
      justify-content: center;
      text-align: center;
    }

    .tytotech {
      justify-content: center;
      padding-bottom: 15px;
    }
  }
}