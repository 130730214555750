@import "../../themes/variables";

#listagemBlogs {
  &.resumo {
    // border: 1px solid gray;
    height: fit-content;

    .swiper.swiper-initialized {
      cursor: e-resize;
      height: 100%;
    }
  }

  &.normal {
    width: 100%;
    // background-color: green;
    .item {
      margin-bottom: 1.5rem;
    }

    .notFoundBlogs {
      height: 100px;
      justify-content: center;
      display: flex;
      align-items: center;

      color: $gray-color;
      font-size: $fonte-grande;
      font-family: 'Poppins-Bold';
    }
  }

  &.resumo,
  &.normal {
    .item {
      width: 100%;
      height: 300px;
      padding: 15px;
      position: relative;

      &:hover {
        .img {
          filter: brightness(30%);
        }

        .esconder {
          transition: all 0.3s linear !important;
          margin-top: 20px !important;
          height: 35px !important;
        }
      }

      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          filter: (brightness(70%));
          z-index: 1;
        }
      }

      .body {
        position: relative;
        z-index: 2;
        overflow: hidden;
        transition: all 3s linear;
        width: 100%;
        height: 100%;

        .content {
          // background-color: red;
          color: $second-color;
          position: absolute;
          display: flex;
          flex-direction: column;
          bottom: 0;
          line-height: 17px;

          & > * {
            margin-bottom: 5px;
          }
          .title1 {
            font-family: 'Poppins-Regular';
            font-size: $fonte-expequena;
          }
          .title2 {
            font-family: 'Poppins-Bold';
            font-size: $fonte-pequena;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
            line-height: 17px;
            height: 34px;
          }
          .text {
            font-family: 'Poppins-Regular';
            font-size: $fonte-pequena;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
            -webkit-box-orient: vertical;
            line-height: 17px;
            height: 68px;
          }

          .esconder {
            height: 0;
            overflow: hidden;
            position: relative;
            transition: all 0.3s linear !important;
            .link {
              font-family: 'Poppins-Regular';
              font-size: $fonte-pequena;
              font-style: none;
              outline: none;
              text-decoration: none;
              color: $second-color;

              span {
                position: absolute;
                top: 0;
                padding: 7px 10px;
                border-radius: 30px;
                background-color: $second-theme-color;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 991.99px) {
  #listagemBlogs {
    &.resumo {
      overflow: hidden;
      .swiper {
        width: calc(100vw + 80px);
      }
      .item {
        height: calc((100vh - 330px));
        padding-bottom: 0;
      }

      .last-end {
        visibility: hidden;
      }
    }
  }
}

// --------------- skeletons -------------------------

#listagemBlogs {
  .skeletonItemLB {
    width: 100%;
    height: 300px;
    margin-bottom: 1rem;
  }
}
