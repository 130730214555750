// @font-face {
// 	font-family: "Poppins-Light";
// 	font-display: auto;
// 	src: url('./../assets/fonte/Poppins-Light.ttf');
// }

@font-face {
	font-family: "Poppins-Regular";
	font-display: swap;
  src: url('./../assets/fonte/Poppins-Regular.woff2') format('woff2'),
       url('./../assets/fonte/Poppins-Regular.woff') format('woff');
}

@font-face {
	font-family: "Poppins-Bold";
	font-display: swap;
	src: url('./../assets/fonte/Poppins-Bold.woff2') format('woff2'),
       url('./../assets/fonte/Poppins-Bold.woff') format('woff');
}

:root {
    //Tema Vermelho
    --red-color: #e30011;
    //Tema Laranja
    --orange-color: #FF5001;
    //Tema Roxo
    --purple-color: #4b1baf;
    //Tema Azul
    --blue-color: #1e5bc6;
    //Tema Verde
    --green-color: #00A859;
    //Tema Amarelo
    --yellow-color: rgb(250, 250, 9);
  
    --highlight-orange: #FF5001;
  
    --theme-color: #1e5bc6;
  
    --dark-purple-color: #371580;
  
    --white-color: #ffffff;
  
    --gray-color: #9DA1A5;
    --shadow-color: #F4F4F5;

  
    --black-color: #111111;
  
    --gray-dark-color: #1E1D1D;
  
    --font40px: 40px;
    --font32px: 30px;
    --font24px: 24px;
    --font20px: 20px;
    --font18px: 18px;
    --font16px: 16px;
    --font14px: 14px;
    --font12px: 12px;
    --font10px: 10px;
  }
  
  $theme-color: var(--white-color);
  $second-theme-color: var(--green-color);
  $third-theme-color: #112437;

  $shadow-color: var(--shadow-color);
  
  $primary-color: var(--black-color);
  $second-color: var(--white-color);
  $third-color: var(--gray-color);

  $gray-color: var(--gray-color);
  
  /************************************************/
  /**************** Fontes *****************/
  /************************************************/
  
  $fonte-titulo-sessao: var(--font32px);
  $fonte-titulo-checkout: var(--font20px);
  $fonte-descricao-produtos: var(--font14px);
  
  $fonte-exgigante: var(--font40px);
  $fonte-gigante: var(--font32px);
  $fonte-exgrande: var(--font24px);
  $fonte-grande: var(--font20px);
  $fonte-exmedia: var(--font18px);
  $fonte-media: var(--font16px);
  $fonte-pequena: var(--font14px);
  $fonte-expequena: var(--font12px);
  $fonte-mini: var(--font10px);

  $padding-page-top: 80px;
  $padding-page-bottom: 80px;
  $padding-page: 80px;


  $max-mobile: 991.98px;
  $min-desktop: 991.99px;


  $header-height: 130px;
  $header-section-padding: 230px;
