@import '../../themes/variables';

.produtoCardLink {
  text-decoration: none;
}


.produtoCard {
  position: relative;

  &.withBorder {
    border: 1px solid $gray-color;
    border-radius: 5px;
  }
  
  .favoritoBotao {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    .favorito {
      fill: $second-theme-color;
      width: 20px;
      height: 20px;
    }
  }

  .imagem {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .descricaoBox {
    padding: 10px 5px;

    .descricao {
      font-family: 'Poppins-Regular';
      width: 100%;
      height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: $fonte-media;
      height: calc($fonte-media * 3);
      color: $primary-color;
    }
  }

  .containerBaixarFicha{
    .botao,.botao:hover,.botao:active {
      border-radius: 0;
      color: $second-theme-color;
      border-color: $second-theme-color;
      font-size: $fonte-expequena;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      width: 100%;
      background-color: white;
      box-shadow: none;
    }

    .botao:hover {
      background-color: $second-theme-color;
      color: $theme-color;
    }
  }
}


@media (max-width: 991.98px) {
  .produtoCard {
    .imagem {
      height: 360px;
    }

    .favoritoBotao {
      width: 50px;
      height: 45px;

      .favorito {
        width: 20px;
        height: 20px;
      }
    }
  }
}