@import '../../../themes/variables';

#sessaoListagemProdutos {
    min-height: 100vh;
    padding-bottom: $padding-page-bottom;

    .vejaMais {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .botao {
            cursor: pointer;
            border: 2px solid $second-theme-color;
            padding: 10px 4rem;
            font-size: $fonte-media;
            font-weight: bold;
            color: $second-theme-color;
        }
    }

    .breadcrumbs {
        margin-bottom: 50px;

        .tituloSubcategoria {
            color: $primary-color;
            font-family: 'Poppins-Bold';
            font-size: $fonte-exgigante;
        }

        .links {
            a {
                text-decoration: none;
            }
            span {
                font-family: 'Poppins-Regular';
                color: $primary-color;
                font-size: $fonte-media;
                margin-right: 0.5rem;
                font-weight: normal;
            }
        }
    }

    .naoEncontrado {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        span {
            color: $gray-color;
            font-size: $fonte-grande;
            font-weight: bold;
        }
    }
}

#listagemProdutosCards {
    .cardProduto {
        margin-bottom: 15px;
    }
}



// Skeletons styles

#sessaoListagemProdutos {
    .skeletonListagemProdutosCards {
        width: 100%;
        height: 352px;
        margin-bottom: 15px;
    }

    .breadcrumbSkeleton {
        width: 300px;
        height: 70px;
    }

    @media (max-width: 991.98px) {
        .skeletonListagemProdutosCards {
            height: 462px;
        }

        .breadcrumbSkeleton {
            width: 100%;
        }
    }
}