@import '../../../themes/variables';

.produtosRelacionados {

  .titulo {
    color: $primary-color;
    border-bottom: 1px solid $second-theme-color;
    font-size: $fonte-grande;
    font-family: 'Poppins-Bold';
    padding: 1rem 0;
    margin-bottom: 3rem;
  }

  .produtosRelacionadosCarousel {

    .carousel-control-prev {
      justify-content: start;
      left: -15px;
    }

    .carousel-control-next {
      justify-content: end;
      right: -15px;
    }

    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;
      height: 70%;
      top: 50px;
      width: 7%;

      span {
        background-color: $second-theme-color;
        border: 3px solid $second-theme-color;
      }
    }

    @media (max-width: 991.98px) {
      .carousel-indicators {
        bottom: -55px;

        button {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $gray-color;
          margin-right: 10px;
          margin-left: 10px;

          &.active {
            background-color: $second-theme-color;
          }
        }
      }

      .carousel-control-next,
      .carousel-control-prev {
        display: none;
      }
    }

  }
}


// Skeletons 

.produtosRelacionados {
  .skeletonProduto {
    height: 352px;
  }

  .skeletonTitulo {
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 3rem;
      .span {
        height: 24px;
        width: 200px;
      }
  }

  @media (max-width: 991.98px) {
    .skeletonProduto {
      height: 462px;
    }
  }
}