@import '../../../themes/variables';

a.whatsapp {
  text-decoration: none;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  left: 15px;
  background-color: $second-theme-color;
  border-radius: 100px;
  text-align: center;
  font-size: 20px;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 30px;
    color: #FFFFFF;
  }
}