@import "../../../themes/variables";

#SessaoProdutosFavoritos {
  padding: (130px + $padding-page) 0 $padding-page 0;
  font-family: 'Poppins-Regular';
  .containerLoading {
    .containerEscritaLoading {
      margin-bottom: 30px;
      .tituloLoading {
        height: $fonte-exgigante;
        width: 300px;
        margin-bottom: 5px;
      }

      .caminhoLoading {
        height: $fonte-titulo-checkout;
        width: 200px;
        margin-bottom: 5px;
      }

      .textoLoading {
        height: $fonte-media;
      }
    }
    .containerCardLoading{
      margin-bottom: 60px;
      .cardLoading{
        aspect-ratio: .8;
      }
    }
    .containerBotaoLoading{
      text-align: center;
      .botaoLoading{
        width:205px;
        height: 40px;
      }
    }
  }

  .containerTexto {
    margin-bottom: 30px;

    .titulo {
      font-family: 'Poppins-Bold';
      font-size: $fonte-exgigante;
    }

    .caminho {
      font-size: $fonte-titulo-checkout;
    }

    .texto {
      font-size: $fonte-media;
    }
  }

  .containerProdutos {
    font-family: 'Poppins-Bold';
    margin-bottom: 60px;
    .containerProduto{
      margin-bottom: 30px;
    }
    .containerBaixarFicha {
      font-family: 'Poppins-Regular';
     }
     
  }

  .containerBotao {
    display: flex;
    justify-content: center;

    .botao{
      border-radius: 0;
      color: $second-theme-color;
      border-color: $second-theme-color;
      font-size: $fonte-expequena;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      box-shadow: none;
      background-color: white;
    }

    .botao:hover {
      background-color: $second-theme-color;
      color: $theme-color;
    }
  }
}