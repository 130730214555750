@import '../../../themes/variables';

#sessaoBlog {
  color: $second-theme-color;
  padding-top: $padding-page-top;

  .layout {

    .listagemBox, .tabelaBox {}

    .internaBox {}

    .tabelaBox {}
  }
}

@media (max-width: 991.98px) {
  #sessaoBlog {
    .layout {

      .tabelaBox {
        margin-bottom: 2rem;
      }
      
      .internaBox {
        order: 2;
      }

      .tabelaBox {
        order: 1;
      }
    }
  }
}

@media (min-width: 991.99px) {
  #sessaoBlog {}
}