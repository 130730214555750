@import '../../themes/variables';

.modalPesquisa {

  .modal-content {
    background-color: rgba($color: #000000, $alpha: .85);

    .closeModal {
      fill: whitesmoke;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1100;
      cursor: pointer;
    }

    .modal-header {
      height: 0;
      padding: 0;
      border: none;
    }

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }

    .boxPesquisa {
      .inputPesquisa {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $second-color;
        padding: 10px 20px;
        font-family: 'Poppins-Regular';
        input {
          background-color: transparent;
          border: none;
          outline: none;
          color: $second-color;
          font-weight: normal;
          &::placeholder {
            color: rgba($color: white, $alpha: .7);
          }
        }

        .iconSearch {
          margin-left: 1rem;
          cursor: pointer;
        }
      }

      .inputAlert {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: $fonte-pequena;
          color:rgba($color: #ffffff, $alpha: .7);
          a {
            color: white;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media (max-width: 991.98px) {

  }

  @media (min-width: 991.99px) {
    .inputPesquisa {
      input {
        width: 350px;
      }
    }
  }
}